import { ReactNode } from 'react'
import s from './FlatTable.scss'
import cn from 'classnames'

type Column<T> = {
  key: string
  title: string | ((col: Column<T>) => ReactNode)
  render?: (row: T, rowIndex: number) => ReactNode
}

type FlatTableProps<T extends { [key: string]: string | ReactNode }> = {
  columns: Column<T>[]
  rows: T[]
  className?: string
  title?: string
  actions?: ReactNode
  hasBorder?: boolean
}

const FlatTable = ({
  columns,
  rows,
  className,
  title,
  actions,
  hasBorder
}: FlatTableProps<{ [key: string]: string | ReactNode }>) => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        {title && <p className={s.tableTitle}>{title}</p>}
        {actions && <div className={s.tableActions}>{actions}</div>}
      </div>
      <div className={cn(s.tableContainer, { [s.tableBorder]: hasBorder })}>
        <table className={s.table}>
          <thead>
            <tr>
              {columns.map(col => (
                <th key={col.key}>
                  {typeof col.title === 'function' ? col.title(col) : col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={s.tableBody}>
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map(col => (
                    <td key={col.key}>{col.render?.(row, rowIndex) || row[col.key]}</td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className={s.emptyTable}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FlatTable
