import { ModalContainer } from 'simple-core-ui'

interface Props {
  onConfirm: () => void
  onCancel: () => void
  userName: string
  view: 'courtRules' | 'fileSharing' | 'budgets' | 'updates'
}

const DeleteConfirmation = ({ onConfirm, onCancel, userName, view }: Props) => {
  const deleteTextMap = {
    courtRules: 'when Events with Court Rules are updated',
    fileSharing: 'for shared files',
    budgets: 'on budget health',
    updates: 'for updates from vendors'
  }

  return (
    <ModalContainer
      title="Delete User"
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Confirm"
      confirmCb={onConfirm}
      content={
        <>
          Are you sure you want to remove {userName} from receiving notifications
          {deleteTextMap[view]}
        </>
      }
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default DeleteConfirmation
