import { FlatTable } from './FlatTable'
import s from './TimekeeperProfileDetails.scss'
import { Tabs } from 'timekeepers/Tabs'
import { useState } from 'react'
import cn from 'classnames'
import { PiCheckCircleFill, PiXCircleFill } from 'react-icons/pi'
import { InfoCard } from './InfoCard'

interface Props {
  setShowTkProfile: (show: boolean) => void
}

const COLUMNS = [
  { key: 'billedRate', title: 'Billed Rate' },
  { key: 'rackRate', title: 'Rack Rate' },
  { key: 'currency', title: 'Currency' },
  { key: 'effectiveDate', title: 'Effective Date' },
  { key: 'type', title: 'Type' },
  { key: 'matter', title: 'Matter' },
  { key: 'requestReason', title: 'Request Reason' },
  { key: 'classComparison', title: 'Class Comparison' },
  { key: 'change', title: 'Change' },
  { key: 'actions', title: 'Actions' }
]

const ROWS = [
  {
    billedRate: '100000000',
    rackRate: '120',
    currency: 'USD',
    effectiveDate: '2023-01-01',
    type: 'Type A',
    matter: 'Matter 1',
    requestReason: 'Reason 1',
    classComparison: 'Comparison 1',
    change: 'Change 1',
    actions: 'Action 1'
  },
  {
    billedRate: '200',
    rackRate: '220',
    currency: 'USD',
    effectiveDate: '2023-02-013478567345',
    type: 'Type B',
    matter: 'Matter 2',
    requestReason: 'Reason 2',
    classComparison: 'Comparison 2',
    change: 'Change 2',
    actions: 'Action 2'
  },
  {
    billedRate: '300',
    rackRate: '320',
    currency: 'USD',
    effectiveDate: '2023-03-01',
    type: 'Type C',
    matter: 'Matter 3',
    requestReason: 'Reason 3',
    classComparison: 'Comparison 3',
    change: 'Change 3',
    actions: 'Action 3'
  },
  {
    billedRate: '400',
    rackRate: '420',
    currency: 'USD',
    effectiveDate: '2023-04-01',
    type: 'Type D',
    matter: 'Matter 4',
    requestReason: 'Reason 4',
    classComparison: 'Comparison 4',
    change: 'Change 4',
    actions: 'Action 4'
  },
  {
    billedRate: '500',
    rackRate: '520',
    currency: 'USD',
    effectiveDate: '2023-05-01',
    type: 'Type E',
    matter: 'Matter 5',
    requestReason: 'Reason 5',
    classComparison: 'Comparison 5',
    change: 'Change 5',
    actions: 'Action 5'
  },
  {
    billedRate: '600',
    rackRate: '620',
    currency: 'USD',
    effectiveDate: '2023-06-01',
    type: 'Type F',
    matter: 'Matter 6',
    requestReason: 'Reason 6',
    classComparison: 'Comparison 6',
    change: 'Change 6',
    actions: 'Action 6'
  },
  {
    billedRate: '700',
    rackRate: '720',
    currency: 'USD',
    effectiveDate: '2023-07-01',
    type: 'Type G',
    matter: 'Matter 7',
    requestReason: 'Reason 7',
    classComparison: 'Comparison 7',
    change: 'Change 7',
    actions: 'Action 7'
  },
  {
    billedRate: '800',
    rackRate: '820',
    currency: 'USD',
    effectiveDate: '2023-08-01',
    type: 'Type H',
    matter: 'Matter 8',
    requestReason: 'Reason 8',
    classComparison: 'Comparison 8',
    change: 'Change 8',
    actions: 'Action 8'
  },
  {
    billedRate: '900',
    rackRate: '920',
    currency: 'USD',
    effectiveDate: '2023-09-01',
    type: 'Type I',
    matter: 'Matter 9',
    requestReason: 'Reason 9',
    classComparison: 'Comparison 9',
    change: 'Change 9',
    actions: 'Action 9'
  },
  {
    billedRate: '1000',
    rackRate: '1020',
    currency: 'USD',
    effectiveDate: '2023-10-01',
    type: 'Type J',
    matter: 'Matter 10',
    requestReason: 'Reason 10',
    classComparison: 'Comparison 10',
    change: 'Change 10',
    actions: 'Action 10'
  },
  {
    billedRate: '1100',
    rackRate: '1120',
    currency: 'USD',
    effectiveDate: '2023-11-01',
    type: 'Type K',
    matter: 'Matter 11',
    requestReason: 'Reason 11',
    classComparison: 'Comparison 11',
    change: 'Change 11',
    actions: 'Action 11'
  },
  {
    billedRate: '1200',
    rackRate: '1220',
    currency: 'USD',
    effectiveDate: '2023-12-01',
    type: 'Type L',
    matter: 'Matter 12',
    requestReason: 'Reason 12',
    classComparison: 'Comparison 12',
    change: 'Change 12',
    actions: 'Action 12'
  },
  {
    billedRate: '1300',
    rackRate: '1320',
    currency: 'USD',
    effectiveDate: '2024-01-01',
    type: 'Type M',
    matter: 'Matter 13',
    requestReason: 'Reason 13',
    classComparison: 'Comparison 13',
    change: 'Change 13',
    actions: 'Action 13'
  },
  {
    billedRate: '1400',
    rackRate: '1420',
    currency: 'USD',
    effectiveDate: '2024-02-01',
    type: 'Type N',
    matter: 'Matter 14',
    requestReason: 'Reason 14',
    classComparison: 'Comparison 14',
    change: 'Change 14',
    actions: 'Action 14'
  },
  {
    billedRate: '1500',
    rackRate: '1520',
    currency: 'USD',
    effectiveDate: '2024-03-01',
    type: 'Type O',
    matter: 'Matter 15',
    requestReason: 'Reason 15',
    classComparison: 'Comparison 15',
    change: 'Change 15',
    actions: 'Action 15'
  },
  {
    billedRate: '1600',
    rackRate: '1620',
    currency: 'USD',
    effectiveDate: '2024-04-01',
    type: 'Type P',
    matter: 'Matter 16',
    requestReason: 'Reason 16',
    classComparison: 'Comparison 16',
    change: 'Change 16',
    actions: 'Action 16'
  },
  {
    billedRate: '1700',
    rackRate: '1720',
    currency: 'USD',
    effectiveDate: '2024-05-01',
    type: 'Type Q',
    matter: 'Matter 17',
    requestReason: 'Reason 17',
    classComparison: 'Comparison 17',
    change: 'Change 17',
    actions: 'Action 17'
  },
  {
    billedRate: '1800',
    rackRate: '1820',
    currency: 'USD',
    effectiveDate: '2024-06-01',
    type: 'Type R',
    matter: 'Matter 18',
    requestReason: 'Reason 18',
    classComparison: 'Comparison 18',
    change: 'Change 18',
    actions: 'Action 18'
  },
  {
    billedRate: '1900',
    rackRate: '1920',
    currency: 'USD',
    effectiveDate: '2024-07-01',
    type: 'Type S',
    matter: 'Matter 19',
    requestReason: 'Reason 19',
    classComparison: 'Comparison 19',
    change: 'Change 19',
    actions: 'Action 19'
  },
  {
    billedRate: '2000',
    rackRate: '2020',
    currency: 'USD',
    effectiveDate: '2024-08-01',
    type: 'Type T',
    matter: 'Matter 20',
    requestReason: 'Reason 20',
    classComparison: 'Comparison 20',
    change: 'Change 20',
    actions: 'Action 20'
  }
]

const INFO = [
  { key: 'Timekeeper ID', value: '0010' },
  { key: 'Timekeeper Name', value: 'Kelly Taylor' },
  { key: 'Timekeeper Type', value: 'Partner' },
  { key: 'Practice Area', value: 'Corporate Law' },
  { key: 'Office Location', value: 'New York' },
  { key: 'Email', value: 'kelly.taylor@example.com' },
  { key: 'Phone', value: '+1-234-567-8901' }
]

const tabs = [
  {
    label: 'Rates',
    value: 'rates'
  },
  {
    label: 'Profile',
    value: 'profile'
  },
  {
    label: 'History',
    value: 'history'
  }
]

const isNew = true

const TimekeeperProfileDetails = ({ setShowTkProfile }: Props) => {
  const [selectedTab, setSelectedTab] = useState('rates')

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
  }

  const actionButtons = (
    <div className={s.actionsContainer}>
      <div className={s.statusButton}>
        <PiCheckCircleFill color="#40A674" />
        <span>Approve All</span>
      </div>
      <div className={s.statusButton}>
        <PiXCircleFill color="#D3455B" />
        <span>Reject All</span>
      </div>
    </div>
  )

  return (
    <div className={s.container}>
      <div className={s.header}>
        <p className={s.timekeeperName}>
          {'Kelly Taylor'}
          {isNew ? <span className={s.badge}>New Timekeeper</span> : null}
        </p>
        <p className={s.timekeeperMeta}>
          {'ID 0010'}
          <span className={s.bullet}>&bull;</span>
          <span className={s.date}>{'Created 01/01/2023'}</span>
        </p>
      </div>
      <div className={s.close} onClick={() => setShowTkProfile(false)} data-testid="add-task-close">
        &times;
      </div>
      <Tabs
        listClassName={cn(s.alignLeft, s.tabs)}
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={changeTab}
      />
      <FlatTable title={'Pending Updates'} actions={actionButtons} columns={COLUMNS} rows={ROWS} />
      <InfoCard info={INFO} title={'General Information'} />
    </div>
  )
}

export default TimekeeperProfileDetails
